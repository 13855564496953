<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo" >
        <span >
          <b-img
            :src="appLogoImageText"
            alt="logo"
            style="max-width: 180px"
          />
        </span>
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Link inválido ⚠️ 
        </h2>
        <p class="mb-2">
          Oops! 😖 O link expirou.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/forgot'}"
        >
          Voltar para esqueci minha senha
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error-troika.svg'),
    }
  },
  computed: {
    appLogoImageText(){
      const { appLogoImageText, appLogoImageTextDarkTheme } = $themeConfig.app
      return localStorage.getItem('vuexy-skin') === 'dark' ? appLogoImageTextDarkTheme: appLogoImageText
    },     
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
